<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>
              <v-icon class="mr-3" color="primary"> mdi-tag-multiple </v-icon
              >Categorias
            </h3>
            <v-btn class="ml-3" color="primary" @click="newCategory">
              Adicionar categoria
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" :to="{ name: 'group-list' }" exact>
              Voltar
            </v-btn>
          </v-card-title>
          <v-data-table
            :loading="loadingCategory"
            :search="search"
            :headers="columns"
            :items="categories_list"
            locale="pt"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon>{{ item.icon }}</v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top v-if="item.editable">
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editCategory(item.id)"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top v-if="item.deletable">
                <template v-slot:activator="{ on }">
                  <v-icon small @click="deleteCategoryItem(item)" v-on="on">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      selectedCategory: {},
      dialog: false,
      search: "",
      loadingCategory: true,
      columns: [
        { text: "Nome", value: "name" },
        { text: "Icone", value: "icon" },
        { text: "Data de cadastro", value: "created_at_format" },
        { text: "Data de atualização", value: "updated_at_format" },
        { text: "", align: "end", value: "actions", sortable: false }
      ]
    };
  },

  async mounted() {
    await this.loadCategoriesList().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loadingCategory = false;
  },

  computed: {
    ...mapState("categories", ["categories_list"])
  },

  methods: {
    ...mapActions("categories", ["loadCategoriesList", "deleteCategory"]),

    newCategory() {
      this.$router.push({ name: "category-new" });
    },

    editCategory(id_category) {
      this.$router.push({ name: "category-edit", params: { id: id_category } });
    },

    dialogCategoryGroup(category) {
      this.dialog = true;
      this.selectedCategory = category;
    },

    async deleteCategoryItem(category) {
      this.loadingCategory = true;
      await this.$swal({
        icon: "warning",
        text: `Tem certeza que deseja excluir a categoria ${category.name}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#445E93",
        confirmButtonText: "Sim, remover a categoria!"
      }).then(async result => {
        if (result.value) {
          await this.deleteCategory(category).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });
        }
      });
      this.loadingCategory = false;
    }
  }
};
</script>
